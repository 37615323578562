import React, { useEffect } from "react";
import SlidingInterval from "./Carousel";
import VirtualTour from "./VirtualTour";
import About from "./About";
import TeacherMessage from "./TeacherMessage";
import StudentVoice from "./StudentVoice";
import Features from "./Features";
import Admission from "./Admission";
import Courses from "./Courses";
import Notice from "./Notice";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="min-h-screen" id="header">
      <SlidingInterval />

      <VirtualTour />

      <About />

      <Courses />

      <Features />

      <Admission />

      <Notice />

      <TeacherMessage />

      <StudentVoice />
    </main>
  );
};

export default Home;
