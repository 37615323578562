import React from "react";
import DBHeader from "./DBHeader";
import { Route, Routes } from "react-router-dom";
import AddNewPhoto from "./AddNewPhoto";
import AddNewNotice from "./AddNewNotice";
import Gallery from "./Gallery";
import Home from "./Home";
import Notice from "./Notice";
import FeaturedImage from "./FeaturedImage";

const DBRight = () => {
  return (
    <section className=" py-5 px-8 flex-1 bg-[rgb(235,235,235)] overflow-y-auto  max-h-screen relative ">
      <DBHeader />

      <div className="flex flex-col flex-1  ">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/featured" element={<FeaturedImage />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/addnewphoto" element={<AddNewPhoto />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/addnewnotice" element={<AddNewNotice />} />
        </Routes>
      </div>
    </section>
  );
};

export default DBRight;
