import React from "react";

const About = () => {
  return (
    <section className="w-full  relative flex-col md:flex-row flex md:gap-10 items-center  bgpattern bg-gray-100   border-gray-300">
      <div className="grid md:grid-cols-2 gap-8 md:gap-0 place-items-center md:pl-5">
        <div className="text-[14px] sm:text-base  z-40 px-4  h-full  md:py-8 text-justify sm:pl-8">
          <h2 className="max-w-lg  font-sans text-3xl font-bold leading-none tracking-tight text-blue-800  sm:text-4xl  px-3 md:px-0 pt-5 md:pt-0">
            WHO ARE WE ?
          </h2>

          <p className="text-headingColor  mt-4   text-justify leading-5 ">
            Orbit School, located at the centre of Bharatpur Metropolitan City (BMP-05, Bikash
            Chowk), is a learning centre for quality and value based education. The school was
            established in 2063 B.S. has been undertaken by dynamic team of academicians of the
            Chitwan district since the year 2073 B.S.
          </p>
          <div className="flex items-center text-blue-800 mt-4 gap-2">
            <svg
              stroke="currentColor"
              className="w-12 text-orange-500"
              fill="none"
              strokeWidth="0"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
              ></path>
            </svg>
            <p className="font-semibold text-xl  text-headingColor">Vision</p>
          </div>
          <p className="text-headingColor leading-5 mt-1 flex gap-2 items-center  md:pl-4 ">
            To create leaders of the future, equipped with the skills of tomorrow, entrepreneurs,
            and responsible citizens.
          </p>

          <div className="flex items-center text-blue-800 gap-2 mt-4 ">
            <svg
              stroke="currentColor"
              className="w-10 font-semibold text-orange-500"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M992 480h-97.44C879.168 293.472 730.512 144.96 544 129.536V32c0-17.664-14.336-32-32-32s-32 14.336-32 32v97.536C293.504 144.96 144.832 293.472 129.424 480H32c-17.664 0-32 14.336-32 32s14.336 32 32 32h97.424C144.832 730.512 293.504 879.04 480 894.464V992c0 17.664 14.336 32 32 32s32-14.336 32-32v-97.536C730.512 879.04 879.168 730.512 894.56 544H992c17.664 0 32-14.336 32-32s-14.336-32-32-32zM480 193.584V480H193.552C208.56 328.8 328.8 208.592 480 193.584zM193.552 544H480v286.416C328.8 815.408 208.56 695.2 193.552 544zM544 830.416V544h286.448C815.44 695.2 695.2 815.408 544 830.416zM544 480V193.584C695.2 208.592 815.44 328.8 830.448 480z"></path>
            </svg>
            <h2 className="font-semibold text-xl text-headingColor">Mission</h2>
          </div>

          <p className="text-headingColor leading-5 mt-1 flex gap-3  md:pl-4" id="academics">
            To strive for quality education in keeping with the motto of the college, "open up your
            future" and empower learners with transdisciplinary knowledge & skills to compete in the
            21st century.
          </p>
        </div>

        <iframe
          className="aspect-video w-full lg:w-[500px] md:rounded-lg shadow-lg  md:mt-0 mt-5"
          src="https://www.youtube.com/embed/_DrLrNR00g4"
          title="Orbit College SS | Open Up Your Future | Bharatpur-5 | Bikash Chowk | Virtual Tour Expo |"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
};

export default About;
