import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const Admission = () => {
  const [admissionStatus, setAdmissionStatus] = useState("");

  useEffect(() => {
    const fetchAdmissionStatus = async () => {
      try {
        const docRef = doc(db, "admissionStatus", "status");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setAdmissionStatus(docSnap.data().status);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchAdmissionStatus();
  }, []);

  return (
    <div className="bg-blue-950 relative" id="admissions">
      <div className="px-6 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
            <a href="/" className="mb-6 sm:mx-auto">
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-orange-500">
                <svg
                  stroke="blue"
                  viewBox="0 0 52 52"
                  className="w-10 h-10 text-deep-purple-900"
                >
                  <polygon
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  ></polygon>
                </svg>
              </div>
            </a>
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                <span className="relative inline-block text-purple-400">
                  <svg
                    viewBox="0 0 52 24"
                    fill="currentColor"
                    className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-accent-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                  >
                    <defs>
                      <pattern
                        id="700c93bf-0068-4e32-aafe-ef5b6a647708"
                        x="0"
                        y="0"
                        width=".135"
                        height=".30"
                      >
                        <circle cx="1" cy="1" r=".7"></circle>
                      </pattern>
                    </defs>
                    <rect
                      fill="url(#700c93bf-0068-4e32-aafe-ef5b6a647708)"
                      width="52"
                      height="24"
                    ></rect>
                  </svg>
                  <span className="relative text-white">{admissionStatus}</span>
                </span>
              </h2>
              <p className="text-base text-indigo-100 md:text-lg">
                Are you ready to jumpstart your career? <br /> Don't miss your
                chance to pre-register for science management, law, fashion
                design, and interior design programs at Orbit +2 College.
              </p>
            </div>
            <div>
              {admissionStatus === "ADMISSION OPEN" ? (
                <Link
                  to="https://docs.google.com/forms/d/e/1FAIpQLSfhWBu4oMliJnaoSBGdS44cc_6IUQOFQvMBhnuQhyf1n4YBMw/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center h-12 px-6 font-semibold tracking-wide text-white transition duration-200 rounded shadow-md hover:text-gray-200 bg-orange-700 hover:bg-orange-500 focus:shadow-outline focus:outline-none"
                >
                  APPLY NOW
                </Link>
              ) : (
                <Link
                  to="https://docs.google.com/forms/d/e/1FAIpQLSfhWBu4oMliJnaoSBGdS44cc_6IUQOFQvMBhnuQhyf1n4YBMw/viewform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center h-12 px-6 font-semibold tracking-wide text-white transition duration-200 rounded shadow-md hover:text-gray-200 bg-orange-700 hover:bg-orange-500 focus:shadow-outline focus:outline-none"
                >
                  ENQUIRY NOW
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admission;
