import React from "react";
import studentvoice1 from "../../assests/studentvoice1.png";
import studentvoice2 from "../../assests/studentvoice2.png";
import studentvoice3 from "../../assests/studentvoice3.png";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const StudentVoice = () => {
  return (
    <div className="w-full bg-gray-200 min-h-[510px] py-20">
      <div className="max-w-xl px-4 mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-orange-400">
            ALUMNI
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block text-blue-800">
            <svg
              viewBox="0 0 52 24"
              fill="gray"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="3071a3ad-db4a-4cbe-ac9a-47850b69e4b7"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7"></circle>
                </pattern>
              </defs>
              <rect fill="url(#3071a3ad-db4a-4cbe-ac9a-47850b69e4b7)" width="52" height="24"></rect>
            </svg>
            WHAT OUR STUDENTS ARE SAYING ?
          </span>
        </h2>
      </div>
      <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 max-w-[1150px] mx-auto mt-10 sm:px-4 lg:px-0 px-8">
        <div className=" h-full px-6 flex flex-col justify-between items-center  bg-blue-200 text-black shadow-lg gap-2 pt-4 pb-5 rounded-lg">
          <LazyLoadImage
            effect="opacity"
            src={studentvoice1}
            className="w-32 h-32  rounded-full "
            alt="student"
            cover
          />

          <p className=" font-bold text-xl">Siddhanta Adhikari</p>
          <p className=" font-serif text-[15px] text-justify px-2 pb-3 opacity-70 relative">
            <BiSolidQuoteAltLeft
              className="inline-block text-xl absolute top-[-10px] left-[-14px]"
              fill="gray"
            />
            Orbit College offers an exceptional education with passionate faculty, a balanced
            curriculum, and a supportive community. The campus facilities are top-notch, and career
            services are comprehensive. Career services are comprehensive, ensuring students are
            well-prepared for their future. They also organize career fairs and networking events
            that allow students to connect with potential employers.
            <BiSolidQuoteAltLeft
              className="inline-block text-xl absolute right-[-10px] transform rotate-180"
              fill="gray"
            />
          </p>
        </div>

        <div className=" h-full px-6 flex flex-col justify-between items-center  bg-blue-200 text-black shadow-lg gap-2 pt-4 pb-5 rounded-lg">
          <LazyLoadImage
            src={studentvoice3}
            className="w-32 h-32  rounded-full "
            alt="student"
            cover
          />

          <p className="font-bold text-xl">Yashashwi Aryal</p>
          <p className=" font-serif text-[15px] text-justify px-2 pb-3 opacity-70 relative">
            <BiSolidQuoteAltLeft
              className="inline-block text-xl absolute top-[-10px] left-[-14px]"
              fill="gray"
            />
            My overall experience with Orbit college has been amazing, and the infrastructure has
            lab and smart class room. Orbit as provided me with a number of opportunities to grow
            and explore my skills. The emphasis on sports along with education helped me a lot. I
            have always found a positive andhealthy enviroment and the teacher are highly
            supportive. I feel proud to be the part of Orbit family.
            <BiSolidQuoteAltLeft
              className="inline-block text-xl absolute right-[-10px] transform rotate-180"
              fill="gray"
            />
          </p>
        </div>

        <div className=" h-full px-6 flex flex-col justify-between items-center  bg-blue-200 text-black shadow-lg gap-2 pt-4 pb-5 rounded-lg">
          <LazyLoadImage
            effect="opacity"
            src={studentvoice2}
            className="w-32 h-32  rounded-full "
            alt="student"
            cover
          />

          <p className=" font-bold text-xl">Arpan Sharma</p>
          <p className=" font-serif text-[15px] text-justify px-2 pb-3 opacity-70 relative">
            <BiSolidQuoteAltLeft
              className="inline-block text-xl absolute top-[-10px] left-[-14px]"
              fill="gray"
            />
            Orbit College fosters a welcoming and inclusive community. The campus is diverse, and
            there are numerous student organizations and clubs that cater to various interests and
            promote a sense of belonging. I have found my involvement in extracurricular activities
            to be enriching and a great way to build friendships and network with like-minded
            individuals. I would recommend Orbit College to those seeking a well-rounded college
            experience.
            <BiSolidQuoteAltLeft
              className="inline-block text-xl absolute right-[-10px] transform rotate-180"
              fill="gray"
            />
          </p>
        </div>
      </section>
    </div>
  );
};

export default StudentVoice;
