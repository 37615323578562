import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { HiSpeakerphone } from "react-icons/hi";
import { motion } from "framer-motion";
import { fade } from "../animations";
import { Link } from "react-router-dom";

const Notices = () => {
  const [notices, setNotices] = useState([]);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "notices"));
        const noticesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const sortedNotices = noticesData.sort((a, b) => {
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
        setNotices(sortedNotices);
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotices();
  }, []);

  return (
    <motion.div
      className="max-w-[1200px] mx-auto min-h-[510px]   pt-14 pb-10 px-1 sm:px-4 md:px-4 "
      {...fade}
    >
      <section className="w-full ">
        <p className="text-blue-800 text-[36px] font-semibold px-4 text-center">
          NEWS AND NOTICES
        </p>
        <ul className="space-y-0 grid grid-cols-1 md:grid-cols-2  w-full gap-4 md:gap-10 mt-5">
          {notices?.map((notice, index) => (
            <div className="  flex" key={index}>
              <p className="flex ">
                <HiSpeakerphone className="text-4xl mt-5 text-blue-800" />
              </p>
              <li
                key={notice.id}
                className="bg-white rounded-lg p-4 flex  justify-between "
              >
                <div>
                  <h3 className="text-gray-900 font-semibold sm:text-lg">
                    {notice.title}
                  </h3>
                  <p className="text-gray-700 line-clamp-4 text-justify mt-1">
                    {notice.description}
                  </p>
                  {notice.link && (
                    <Link
                      to={notice.link}
                      className="text-indigo-500 mt-2 inline-block hover:underline"
                    >
                      Read More
                    </Link>
                  )}
                </div>
              </li>
            </div>
          ))}
        </ul>
      </section>
    </motion.div>
  );
};

export default Notices;
