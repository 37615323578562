import React from "react";
import Navbar from "./components/Navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import { useSelector } from "react-redux";
import Login from "./components/admin/Login";
import Gallery from "./components/Gallery";
import Dashboard from "./components/admin/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notices from "./components/Notices";

const App = () => {
  const RequireAuth = ({ children }) => {
    const { userToken, loading } = useSelector((state) => state.auth);

    if (loading) {
      console.log("yes");
      return (
        <div className="w-screen h-screen flex justify-center items-center">
          <p className="font-bold text-3xl">Loading...</p>
        </div>
      );
    }

    return userToken && localStorage.getItem("userToken") ? (
      children
    ) : (
      <Navigate to="/login" />
    );
  };
  return (
    <div className="w-screen min-h-screen">
      <ToastContainer theme="dark" />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Navbar />
              <Contact />
              <Footer />
            </>
          }
        />
        <Route
          path="/gallery"
          element={
            <>
              <Navbar />
              <Gallery />
              <Footer />
            </>
          }
        />
        <Route
          path="/notices"
          element={
            <>
              <Navbar />
              <Notices />
              <Footer />
            </>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route
          path="/admin/*"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
