import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage"; // Import getStorage and ref functions

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "orbit-3eeec.firebaseapp.com",
  projectId: "orbit-3eeec",
  storageBucket: "orbit-3eeec.appspot.com",
  messagingSenderId: "445730755725",
  appId: "1:445730755725:web:da6fe88804f9c3a78bfec4",
  measurementId: "G-RS9GG2T9NW",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const storageRef = ref(storage); // Update to use ref function with storage

export default app;
