import React, { useEffect, useState } from "react";
import { storageRef } from "../../firebase";
import { listAll, ref, deleteObject, getDownloadURL } from "firebase/storage";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { btnClick } from "../../animations";
import { motion } from "framer-motion";
import { TiDelete } from "react-icons/ti";
import { storage } from "../../firebase";
import { uploadBytesResumable } from "firebase/storage";

const FeaturedImages = () => {
  const [imageUrls, setImageUrls] = useState([]);

  const fetchImages = async () => {
    try {
      const imageList = await listAll(ref(storageRef, "featured/"));
      const imagePromises = imageList.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        return { url, ref: itemRef };
      });
      const images = await Promise.all(imagePromises);
      setImageUrls(images);
    } catch (error) {
      toast.error("Something went wrong.");
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleDeleteImage = (itemRef) => {
    deleteObject(itemRef)
      .then(() => {
        setImageUrls((prevUrls) =>
          prevUrls.filter((item) => item.ref !== itemRef)
        );
        console.log("Image deleted successfully");
      })
      .catch((error) => {
        console.log("Error deleting image:", error);
      });
  };

  return (
    <section className="mt-4">
      <div>
        <h2 className="text-3xl text-headingColor font-bold my-4 mt-8">
          Featured Images
        </h2>
        <div className=" grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3   mx-auto pt-2 pb-4 gap-x-2 gap-y-1 px-2 lg:px-0 ">
          {imageUrls?.map(({ url, ref }, index) => {
            return (
              <div
                key={index}
                className="overflow-hidden h-[14rem] md:h-[12rem] w-full flex group  transition-opacity duration-300 justify-center items-center relative group-hover:opacity-70"
              >
                <LazyLoadImage
                  effect="opacity"
                  className="object-cover  aspect-video hover:scale-105 duration-300   relative group-hover:opacity-50"
                  src={url}
                  alt="gallery"
                />
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  <button
                    onClick={() => handleDeleteImage(ref)}
                    className="bg-red-500 text-white rounded-full p-2 z-50"
                  >
                    <MdDelete size={24} />
                  </button>
                </div>
                <div className="w-full h-full hidden absolute top-0 left-0 z-50 bg-black bg-opacity-75 hover:flex justify-center items-center">
                  <p className="text-white text-xl">Click here</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {imageUrls.length <3 && <AddNewPhoto fetchImages={fetchImages} />}
    </section>
  );
};

const AddNewPhoto = ({ fetchImages }) => {
  const [file, setFile] = useState("");

  const onDrop = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const uploadFile = async () => {
    try {
      if (file.length === 0) {
        toast.error("Please select a image.");
        return;
      }
      const storageRef = ref(storage, `featured/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          toast.error("Error Uploading Image");
        },
        () => {
          // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //   setData((prev) => ({ ...prev, img: downloadURL }));
          // });
          fetchImages();
          toast.success("Image Uploaded Successfully");
          setFile("");
        }
      );
    } catch (error) {
      console.log(error);
      toast.error("Error Uploading Image");
    }
  };

  return (
    <div className=" h-[340px] flex items-center justify-center flex-col mt-12">
      {!file ? (
        <label className="flex justify-center w-[500px] h-[300px] px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
          <span className="flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
            <span className="font-medium text-gray-600 flex gap-2">
              Drop files to Attach, or
              <span className="text-blue-600 underline">Browse</span>
            </span>
          </span>
          <input
            type="file"
            name="file_upload"
            className="hidden "
            onChange={onDrop}
          />
        </label>
      ) : (
        <div className="relative">
          <img
            src={URL.createObjectURL(file)}
            alt="Selected "
            className="w-[500px] h-[300px] aspect-video b object-cover"
          />
          <TiDelete
            className=" text-red-600 absolute top-1 right-1 z-10 text-4xl"
            onClick={() => setFile("")}
          />
        </div>
      )}

      <motion.button
        className="bg-blue-700 px-20 hover:bg-blue-600 py-2 mt-5 text-gray-100 font-semibold rounded-lg"
        onClick={uploadFile}
        {...btnClick}
      >
        Upload Image
      </motion.button>
    </div>
  );
};

export default FeaturedImages;
