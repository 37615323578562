import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Courses = () => {
  return (
    <div className="bg-white">
      <div className="px-4 py-14 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        <div className="grid md:grid-cols-4 sm:grid-cols-1 gap-4">
          <div className="md:col-span-4">
            <div id="tabs-id" className="flex flex-wrap">
              <div className="w-full">
                <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
                  <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">
                    <span className="inline-block mb-1 sm:mb-4">
                      OUR COURSES
                    </span>
                    <div className="h-1 ml-auto duration-300 origin-left transform bg-blue-600 scale-x-30 group-hover:scale-x-100"></div>
                  </h2>
                  <p className="text-gray-700 lg:text-sm lg:max-w-md">
                    Orbit seeks to grasp the ability of awaiting future trends
                    by precisely deriving the existing facts and infusing them
                    into the current education system to help students develop
                    necessary skills and set a realistic career path.
                  </p>
                </div>

                <div className="grid gap-6 row-gap-5 lg:grid-cols-4 sm:row-gap-6 sm:grid-cols-2">
                  <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl border border-gray-400">
                    <div></div>
                    <LazyLoadImage
                      alt=""
                      className="object-cover w-full h-36 md:h-42 xl:h-48"
                      src="https://images.pexels.com/photos/9242838/pexels-photo-9242838.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                    <div className="absolute inset-x-0 bottom-0 px-6 py-2 bg-blue-600 bg-opacity-75">
                      <p className="text-md font-medium tracking-wide text-white text-center">
                        Science & Technology
                      </p>
                    </div>
                    <div className="absolute top-0 left-0 p-1 m-2 bg-red-500 rounded-lg">
                      <span className="text-xs font-bold leading-none text-white">
                        Bio Science
                      </span>
                    </div>
                    <div className="absolute top-0 right-0 p-1 m-2 bg-blue-800 rounded-lg">
                      <span className="text-xs font-bold leading-none text-white">
                        Computer Science
                      </span>
                    </div>
                  </div>

                  <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl border border-gray-400">
                    <div></div>
                    <LazyLoadImage
                      alt=""
                      className="object-cover w-full h-36 md:h-42 xl:h-48"
                      src="https://images.pexels.com/photos/6693661/pexels-photo-6693661.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                    <div className="absolute inset-x-0 bottom-0 px-6 py-2 bg-orange-600 bg-opacity-75">
                      <p className="text-md font-medium tracking-wide text-white text-center">
                        Management
                      </p>
                    </div>
                    <div className="absolute top-0 right-0 p-1 m-2 bg-red-500 rounded-lg">
                      <span className="text-xs font-bold leading-none text-white">
                        Hotel Management
                      </span>
                    </div>
                    <div className="absolute top-0 left-0 p-1 m-2 bg-blue-800 rounded-lg">
                      <span className="text-xs font-bold leading-none text-white">
                        General Management
                      </span>
                    </div>
                  </div>

                  <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl border border-gray-400">
                    {/* <div>
                      <span className="absolute right-1 top-1 px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded">
                        New Courses
                      </span>
                    </div> */}
                    <LazyLoadImage
                      alt=""
                      className="object-cover w-full h-36 md:h-42 xl:h-48"
                      src="https://images.pexels.com/photos/4620616/pexels-photo-4620616.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    />
                    <div className="absolute inset-x-0 bottom-0 px-6 py-2 bg-blue-600 bg-opacity-75">
                      <p className="text-md font-medium tracking-wide text-white text-center">
                        Design and Innovation{" "}
                        <span className="font-semibold text-sm">[NEW]</span>
                      </p>
                    </div>
                    <div className="absolute top-0 right-0 p-1 m-2 bg-red-500 rounded-lg">
                      <span className="text-xs font-bold leading-none text-white">
                        Interior Design
                      </span>
                    </div>
                    <div className="absolute top-0 left-0 p-1 m-2 bg-blue-800 rounded-lg">
                      <span className="text-xs font-bold leading-none text-white">
                        Fashion Design
                      </span>
                    </div>
                  </div>

                  <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl border border-gray-400">
                    <div>
                      {/* <span className="absolute right-1 top-1 px-2 py-1 text-xs font-bold leading-none text-white bg-blue-800 rounded-lg">
                        New Courses
                      </span> */}
                    </div>
                    <LazyLoadImage
                      alt=""
                      className="object-cover w-full h-36 md:h-42 xl:h-48"
                      src="https://images.unsplash.com/photo-1617203443952-6d2619f7ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
                    />
                    <div className="absolute inset-x-0 bottom-0 px-6 py-2 bg-orange-600 bg-opacity-75">
                      <p className="text-md font-medium tracking-wide text-white text-center">
                        School of Law
                        <span className="font-semibold text-sm"> [NEW] </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
