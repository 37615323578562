import { createAsyncThunk } from "@reduxjs/toolkit";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { toast } from "react-toastify";

export const userLogin = createAsyncThunk(
  "user/login",
  async (values, { rejectWithValue }) => {
    // console.log(values);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;
      // console.log(user);
      toast.success("Login successful.");

      return user.accessToken;
    } catch (error) {
      const errorCode = error.code;
      const cleanErrorCode = errorCode.replace("auth/", "");
      toast.error(cleanErrorCode);
      return rejectWithValue(cleanErrorCode);
    }
  }
);
