import React, { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="max-w-xl mb-2 mt-12 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold sm:text-4xl md:mx-auto">
          <span className="relative inline-block text-blue-800">
            CONTACT US
          </span>
        </h2>
      </div>

      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7557.948700237264!2d84.40116674735201!3d27.65588465192508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3994fb33c828513b%3A0x9bc57d9049e9ecdb!2sOrbit%20College%20(%2B2%20Block)!5e0!3m2!1sen!2snp!4v1688026626638!5m2!1sen!2snp"
          title="Orbit College"
          width="100%"
          height="420"
          className="border-0"
          loading="lazy"
        ></iframe>
      </div>

      <div className=" max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8">
        <div className="mt-4 sm:mx-auto sm:w-full">
          <div className=" py-8 px-4 sm:rounded-lg sm:px-10">
            <div className="relative bg-white">
              <div className="absolute inset-0">
                <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
              </div>
              <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                  <div className="max-w-lg mx-auto">
                    <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                      Get in touch
                    </h2>
                    <p className="mt-3 text-lg leading-6 text-gray-500">
                      Orbit College lies in the centre of the city which makes
                      it easily accessible by public transport or otherwise. The
                      College is situated in a quiet, open, and lush spot with a
                      pleasant and peaceful environment
                    </p>
                    <dl className="mt-8 text-base text-gray-500">
                      <div>
                        <p>Bharatpur-05, Bikashchowk </p>
                        <p>Chitwan, Nepal</p>
                      </div>
                      <div className="mt-6">
                        <dt className="sr-only">Phone number</dt>
                        <dd className="flex">
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                            />
                          </svg>
                          <span className="ml-3">
                            {" "}
                            056-418307, 9855060414, <br /> 9846900458
                          </span>
                        </dd>
                      </div>
                      <div className="mt-3">
                        <dt className="sr-only">Email</dt>
                        <dd className="flex">
                          <svg
                            className="flex-shrink-0 h-6 w-6 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                          </svg>
                          <span className="ml-3">
                            <a href="mailto:orbitcollege077@gmail.com">
                              orbitcollege077@gmail.com
                            </a>
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                  <div className="max-w-lg mx-auto lg:max-w-none">
                    <form action="">
                      <div className="grid lg:grid-cols-2  sm:grid-cols-1 gap-5 col-span-2">
                        <div>
                          <label>First Name</label>
                          <input
                            required
                            type="text"
                            name="first_name"
                            className="bg-white border border-solid border-gray-300 w-full rounded px-4 py-2 mt-2 mb-4 block"
                            placeholder="First Name"
                          />
                        </div>
                        <div>
                          <label>Last Name</label>
                          <input
                            required
                            type="text"
                            name="last_name"
                            className="bg-white border border-solid border-gray-300 w-full rounded px-4 py-2 mt-2 mb-4 block"
                            placeholder="Last Name"
                          />
                        </div>
                        <div>
                          <label>Contact No.</label>
                          <input
                            required
                            type="tel"
                            name="phone"
                            className="bg-white border border-solid border-gray-300 w-full rounded px-4 py-2 mt-2 mb-4 block"
                            placeholder="Contact No."
                          />
                        </div>
                        <div>
                          <label>Email</label>
                          <input
                            required
                            type="email"
                            name="email"
                            className="bg-white border border-solid border-gray-300 w-full rounded px-4 py-2 mt-2 mb-4 block"
                            placeholder="Email Address"
                          />
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-1  sm:grid-cols-1 gap-5 col-span-2">
                        <div>
                          <label>Subject</label>
                          <input
                            required
                            type="text"
                            name="subject"
                            className="bg-white border border-solid border-gray-300 w-full rounded px-4 py-2 mt-2 mb-4 block"
                            placeholder="Subject"
                          />
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-1  sm:grid-cols-1 gap-5 col-span-2">
                        <div>
                          <label>Message </label>
                          <textarea
                            required
                            name="message"
                            rows="5"
                            className="bg-white border border-solid border-gray-300 w-full rounded px-4 py-2 mt-2 mb-4 block"
                            placeholder="Your Message"
                          ></textarea>
                        </div>
                      </div>
                      <div className="lg:col-span-2 mt-5">
                        <input
                          type="submit"
                          value="Send Inquiry"
                          className="block w-full py-4 px-4 bg-blue-800 font-bold uppercase rounded text-white text-center cursor-pointer hover:bg-blue-700 hover:text-white transition-colors"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
