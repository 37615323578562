import React, { useEffect, useState } from "react";
import { storageRef } from "../../firebase";
import { listAll, ref, deleteObject, getDownloadURL } from "firebase/storage";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const Gallery = () => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imageList = await listAll(ref(storageRef, "gallery/"));
        const imagePromises = imageList.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return { url, ref: itemRef };
        });
        const images = await Promise.all(imagePromises);
        setImageUrls(images);
      } catch (error) {
        toast.error("Something went wrong.");
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const handleDeleteImage = (itemRef) => {
    deleteObject(itemRef)
      .then(() => {
        setImageUrls((prevUrls) =>
          prevUrls.filter((item) => item.ref !== itemRef)
        );
        console.log("Image deleted successfully");
      })
      .catch((error) => {
        console.log("Error deleting image:", error);
      });
  };

  return (
    <div>
      <h2 className="text-3xl text-headingColor font-bold my-4 mt-8">
        Our Gallery
      </h2>

      <div className=" grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3   mx-auto pt-2 pb-4 gap-x-2 gap-y-1 px-2 lg:px-0 ">
        {imageUrls?.map(({ url, ref }, index) => {
          return (
            <div
              key={index}
              className="overflow-hidden h-[14rem] md:h-[12rem] w-full flex group  transition-opacity duration-300 justify-center items-center relative group-hover:opacity-70"
            >
              <LazyLoadImage
                effect="opacity"
                className="object-cover  aspect-video hover:scale-105 duration-300   relative group-hover:opacity-50"
                src={url}
                alt="gallery"
              />
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                <button
                  onClick={() => handleDeleteImage(ref)}
                  className="bg-red-500 text-white rounded-full p-2 z-50"
                >
                  <MdDelete size={24} />
                </button>
              </div>
              <div className="w-full h-full hidden absolute top-0 left-0 z-50 bg-black bg-opacity-75 hover:flex justify-center items-center">
                <p className="text-white text-xl">Click here</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Gallery;
