import React from "react";
import OrbitLogo from "../assests/OrbitLogo.jpg";
import { Link } from "react-router-dom";
import { AiFillFacebook, AiFillMail, AiFillYoutube } from "react-icons/ai";
import { VscDebugBreakpointFunction } from "react-icons/vsc";

const Footer = () => {
  return (
    <>
      <section className="w-full py-8 bg-[rgb(0,56,122)] text-white  px-1 sm:px-6  text-opacity-80">
        <section className="max-w-screen-xl  mx-auto flex justify-around  ">
          <aside className=" -mt-3 sm:mt-0">
            <div className="flex w-48 gap-2 ">
              <Link to="#header">
                <img
                  src={OrbitLogo}
                  className="w-28 ml-2  rounded-lg  sm:flex  cursor-pointer"
                  alt="logo"
                />
              </Link>
              {/* <div className="text-xl font-bold font-sans leading-6 my-auto cursor-default sm:hidden md:flex">
                <span className=" sm:text-2xl tracking-wide">Orbit </span>
                College
              </div> */}
            </div>

            <div className="w-[12rem] sm:w-[16rem] flex flex-col gap-1  sm:gap-2 leading-5 font-sans text-sm mt-4 ">
              <p>
                <span className="sm:font-medium text-base text-[15px] font-medium cursor-pointer leading-3">
                  Address:{" "}
                </span>{" "}
                Bharatpur-5, Bikashchowk Chitwan
              </p>

              <p>
                <span className="sm:font-medium text-base text-[15px] font-medium cursor-pointer leading-3">
                  Contact:
                </span>{" "}
                056-418307, 9855060414, 9846900458
              </p>

              <p className="flex  sm:hidden gap-4 text-2xl items-center mt-1">
                <Link target="_blank" to="https://www.facebook.com/orbitcolz">
                  {" "}
                  <AiFillFacebook className="hover:text-gray-300 cursor-pointer hover:opacity-80 " />
                </Link>
                <Link target="_blank" to="mailto:orbit@example.com">
                  {" "}
                  <AiFillMail className="hover:text-gray-300 cursor-pointer hover:opacity-80" />
                </Link>
                <Link
                  target="_blank"
                  to="https://www.youtube.com/channel/UCNEMTN6DDdNWiqeLVW24XKQ"
                >
                  <AiFillYoutube className="text-3xl hover:text-gray-300 cursor-pointer hover:opacity-80" />
                </Link>
              </p>
            </div>
          </aside>

          <aside className="font-sans font-semibold flex flex-col gap-1">
            <p className="inline border-b text-[18px] mb-2 w-16">Courses</p>
            <p className="text-sm font-normal sm:text-[15px] flex gap-1 ">
              <VscDebugBreakpointFunction className="rotate-90 text-lg" />
              <span> Science & Technology</span>
            </p>
            <p className="text-sm font-normal sm:text-[15px] flex gap-1">
              <VscDebugBreakpointFunction className="rotate-90 text-lg" />
              Management
            </p>
            <p className="text-sm font-normal sm:text-[15px] flex gap-1">
              <VscDebugBreakpointFunction className="rotate-90 text-lg" />
              Fashion/Interior Design
            </p>
            <p className="text-sm font-normal sm:text-[15px] flex gap-1">
              <VscDebugBreakpointFunction className="rotate-90 text-lg" />
              School of Law
            </p>
          </aside>

          <aside className="font-sans font-semibold  flex-col gap-1 hidden sm:flex">
            <p className="inline border-b text-[18px] mb-2 ">Visit us at</p>

            <p className="flex gap-2 items-center text-lg">
              <Link to="https://www.facebook.com/orbitcolz" target="_blank">
                <AiFillFacebook className="hover:opacity-80 text-[22px] rounded-md" />
              </Link>
              <Link
                to="https://www.facebook.com/orbitcolz"
                target="_blank"
                className="text-[14px] hover:underline font-medium hover:cursor-pointer"
              >
                Facebook
              </Link>
            </p>
            <p className="flex gap-2 items-center">
              {" "}
              <Link to="mailto:orbit@example.com" className="cursor-pointer">
                <AiFillMail className="text-[22px] hover:opacity-80" />
              </Link>
              <Link
                to="mailto:orbit@example.com"
                className="text-[14px] hover:underline font-medium"
              >
                Gmail
              </Link>
            </p>

            <p className="flex gap-2 items-center">
              <Link
                to="https://www.youtube.com/channel/UCNEMTN6DDdNWiqeLVW24XKQ"
                target="_blank"
              >
                <AiFillYoutube className="hover:opacity-80 text-2xl" />
              </Link>
              <Link
                to="https://www.youtube.com/channel/UCNEMTN6DDdNWiqeLVW24XKQ"
                target="_blank"
                className="text-[14px] hover:underline font-medium cursor-pointer"
              >
                Youtube
              </Link>
            </p>
          </aside>

          <aside className=" hidden lg:block -mt-4">
            <p className="text-xl text-center font-semibold pb-2">
              Where we are ?
            </p>

            <iframe
              title="ssss"
              className="w-[350px] h-40 rounded-lg"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7557.948700237264!2d84.40116674735201!3d27.65588465192508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3994fb33c828513b%3A0x9bc57d9049e9ecdb!2sOrbit%20College%20(%2B2%20Block)!5e0!3m2!1sen!2snp!4v1688026626638!5m2!1sen!2snp"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </aside>
        </section>
      </section>
      <div className="bg-[rgb(0,44,96)] flex text-[15px] pt-4 pb-5 px-10">
        <p className="text-gray-100 mx-auto text-center">
          © 2024 Orbit , All Rights Reserved. Developed by{" "}
          <Link
            className="underline opacity-80 hover:text-blue-600 duration-200"
            to="https://hubizen.com/?fbclid=IwAR081lIwyGhtn1i3cX6QTi_oikxIKmtVUF81bnvxY-B9qQzB4cfSI4-AnB0"
            target="_blank"
          >
            {" "}
            Hubizen Innovations
          </Link>
        </p>
      </div>
    </>
  );
};

export default Footer;
