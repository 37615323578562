import React, { useState } from "react";
import { btnClick } from "../../animations";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { TiDelete } from "react-icons/ti";

import { storage } from "../../firebase";
import { ref, uploadBytesResumable } from "firebase/storage";

const AddNewPhoto = () => {
  const [file, setFile] = useState("");

  const onDrop = (e) => {
    setFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const uploadFile = async () => {
    try {
      if (file.length === 0) {
        toast.error("Please select a image.");
        return;
      }
      const storageRef = ref(storage, `gallery/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          toast.error("Error Uploading Image");
        },
        () => {
          // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //   setData((prev) => ({ ...prev, img: downloadURL }));
          // });
          toast.success("Image Uploaded Successfully");
          setFile("");
        }
      );
    } catch (error) {
      console.log(error);
      toast.error("Error Uploading Image");
    }
  };

  return (
    <div className=" h-[540px] flex items-center justify-center flex-col">
      {!file ? (
        <label className="flex justify-center w-[500px] h-[300px] px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
          <span className="flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
            <span className="font-medium text-gray-600 flex gap-2">
              Drop files to Attach, or
              <span className="text-blue-600 underline">Browse</span>
            </span>
          </span>
          <input
            type="file"
            name="file_upload"
            className="hidden "
            onChange={onDrop}
          />
        </label>
      ) : (
        <div className="relative">
          <img
            src={URL.createObjectURL(file)}
            alt="Selected "
            className="w-[500px] h-[300px] aspect-video b object-cover"
          />
          <TiDelete
            className=" text-red-600 absolute top-1 right-1 z-10 text-4xl"
            onClick={() => setFile("")}
          />
        </div>
      )}

      <motion.button
        className="bg-blue-700 px-20 hover:bg-blue-600 py-2 mt-5 text-gray-100 font-semibold rounded-lg"
        onClick={uploadFile}
        {...btnClick}
      >
        Upload Image
      </motion.button>
    </div>
  );
};

export default AddNewPhoto;
