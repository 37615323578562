import React from "react";
import { NavLink } from "react-router-dom";
import Orbit from "../../assests/OrbitLogo.png";

const DBLeft = () => {
  const isActiveStyles =
    "text-[21px] text-2xl text-red-700 font-semibold hover:text-red-700 px-4 py-2 duration-200  tranition-all ease-in-out underline";

  const isNotActiveStyles =
    "text-xl text-textColor font-semibold hover:text-red-700 px-4 py-2  tranition-all ease-in-out";
  return (
    <section className="h-full bg-gradient-to-r from-gray-100 to-gray-300 py-12 hidden md:flex flex-col backdrop-blur-md shadow-md min-w-210 w-300 gap-3 px-[11px] ">
      <NavLink
        to="/"
        className="flex items justify-start gap-4 items-center text-2xl"
      >
        <img src={Orbit} className="w-20 h-20 rounded-full" alt="orbit" />
        <p className="font-semibold text-xl ">Orbit College</p>
      </NavLink>
      <hr className="border-gray-400" />

      <ul className="flex flex-col gap-4">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? `${isActiveStyles} px-4 py-2 border-l-8 border-red-500`
              : isNotActiveStyles
          }
          to={"/admin/"}
        >
          Home
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? `${isActiveStyles} px-4 py-2 border-l-8 border-red-500`
              : isNotActiveStyles
          }
          to={"/admin/featured"}
        >
          Featured Images
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? `${isActiveStyles} px-4 py-2 border-l-8 border-red-500`
              : isNotActiveStyles
          }
          to={"/admin/gallery"}
        >
          Gallery
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? `${isActiveStyles} px-4 py-2 border-l-8 border-red-500`
              : isNotActiveStyles
          }
          to={"/admin/addnewphoto"}
        >
          Add New Photos
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive
              ? `${isActiveStyles} px-4 py-2 border-l-8 border-red-500`
              : isNotActiveStyles
          }
          to={"/admin/notice"}
        >
          Notices
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? `${isActiveStyles} px-4 py-2 border-l-8 border-red-500`
              : isNotActiveStyles
          }
          to={"/admin/addnewnotice"}
        >
          Add New Notice
        </NavLink>
      </ul>
    </section>
  );
};

export default DBLeft;
