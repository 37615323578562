import React, { useState } from "react";
import OrbitLogo from "../assests/OrbitLogo.jpg";
import { motion } from "framer-motion";
import { btnClick, slideTop } from "../animations";
import { TiThMenu } from "react-icons/ti";
import { FaWindowClose } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useDispatch } from "react-redux";
import { toggleLogin } from "../redux/auth/authSlice";
import { FaUserCircle } from "react-icons/fa";

const Navbar = () => {
  const [isMenu, setIsMenu] = useState(false);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };

  const handleLogin = () => {
    dispatch(toggleLogin());
  };

  return (
    <main className="sticky top-0 z-50">
      <nav className=" border-gray-200 bg-blue-800 -ml-2">
        <div className="max-w-screen-xl flex  items-center justify-between mx-auto p-2">
          <Link to="/" className="flex items-center pl-3 lg:pl-0 h-16">
            <img
              src={OrbitLogo}
              className="w-28 object-contain mr-2 bg-white rounded-lg p-2"
              alt="Orbit Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
              College
            </span>
          </Link>

          <div className="flex gap-6 text-lg font-semibold text-white">
            <div className=" gap-8 lg:pr-16 hidden lg:flex  min-h-full">
              <Link
                to="/"
                className="hover:text-gray-200 hover:underline transition-all duration-200"
              >
                Home
              </Link>
              <ScrollLink
                to="about"
                className="hover:text-gray-200 hover:underline transition-all duration-200 cursor-pointer"
                duration={700}
                smooth={true}
              >
                <Link to="/">About</Link>
              </ScrollLink>

              <ScrollLink
                to="academics"
                className="hover:text-gray-200 hover:underline transition-all duration-200 cursor-pointer"
                smooth={true}
                duration={900}
              >
                <Link to="/">Courses</Link>
              </ScrollLink>
              <ScrollLink
                className="hover:text-gray-200 hover:underline transition-all duration-200 cursor-pointer"
                to="admissions"
                smooth={true}
                duration={900}
              >
                <Link to="/">Admissions</Link>
              </ScrollLink>
              <Link
                className="hover:text-gray-200 hover:underline transition-all duration-200 cursor-pointer"
                to="/gallery"
              >
                Gallery
              </Link>
              <Link
                className="hover:text-gray-200 hover:underline transition-all duration-200 cursor-pointer"
                to="/notices"
              >
                Notices
              </Link>
              <Link
                to="/contact"
                className="hover:text-gray-200 hover:underline transition-all duration-200 cursor-pointer"
              >
                Contact
              </Link>
            </div>

            <motion.button
              className="px-4 md:hidden"
              {...btnClick}
              onClick={toggleMenu}
            >
              {!isMenu ? (
                <TiThMenu className="text-4xl" />
              ) : (
                <FaWindowClose className="text-4xl" />
              )}
            </motion.button>

            <motion.button
              {...btnClick}
              onClick={handleLogin}
              className="  rounded-md -mt-1  hidden md:block"
            >
              <Link
                className="w-full h-full  px-4  flex items-center "
                to="/login"
              >
                <FaUserCircle className="text-3xl hover:scale-95  transition-all ease-out duration-200" />
              </Link>
            </motion.button>
            {isMenu && <MobileMenu toggleMenu={toggleMenu} />}
          </div>
        </div>
      </nav>
    </main>
  );
};

export default Navbar;

export const MobileMenu = ({ toggleMenu }) => {
  return (
    <motion.div
      {...slideTop}
      className=" fixed bg-sky-400 left-0 top-20 flex flex-col gap-2   w-full"
    >
      <Link
        to="/"
        onClick={toggleMenu}
        className="px-6 border-b py-1 border-gray-200 pt-2"
      >
        Home
      </Link>

      <ScrollLink
        onClick={toggleMenu}
        to="about"
        className="px-6 border-b py-1 border-gray-200"
        smooth={true}
        duration={700}
      >
        <Link to="/">About</Link>
      </ScrollLink>
      <Link
        to="/notices"
        onClick={toggleMenu}
        className="px-6 border-b py-1 border-gray-200 pt-2"
      >
        Notices
      </Link>
      <ScrollLink
        onClick={toggleMenu}
        to="admissions"
        className="px-6 border-b py-1 border-gray-200"
        smooth={true}
        duration={800}
      >
        <Link to="/">Admissions</Link>
      </ScrollLink>
      <ScrollLink
        to="academics"
        className="px-6 border-b py-1 border-gray-200"
        onClick={toggleMenu}
        smooth={true}
        duration={900}
      >
        <Link to="/">Courses</Link>
      </ScrollLink>

      <Link
        to="/gallery"
        onClick={toggleMenu}
        className="px-6 border-b py-1 border-gray-200"
      >
        Gallery
      </Link>
      <Link to="/contact" onClick={toggleMenu} className="px-6  py-3 ">
        Contact
      </Link>
    </motion.div>
  );
};
