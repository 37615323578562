import { motion } from "framer-motion";
import React, { useState } from "react";
import { btnClick } from "../../animations";
import { toast } from "react-toastify";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";

const AddNewNotice = () => {
  const [data, setData] = useState({ title: "", description: "", link: "" });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const countWords = (str) => {
    const trimmedStr = str.trim();
    const words = trimmedStr.split(/\s+/);
    return words.length;
  };

  const handleClick = async (e) => {
    e.preventDefault();

    if (data.title.length < 5) {
      toast.info("Title must be at least 5 characters.");
      return;
    }

    if (data.title.length > 60) {
      toast.info("Title must be less than 60 characters.");
      return;
    }

    const wordCount = countWords(data.description);

    if (wordCount < 5) {
      toast.info("Description must be at least 5 words.");
      return;
    }

    if (wordCount > 150) {
      toast.info("Description must be less than 150 words.");
      return;
    }

    if (data.link.length > 100) {
      toast.info("Link must be less than 100 characters.");
      return;
    }

    try {
      const notice = {
        title: data.title,
        description: data.description,
        link: data.link,
        createdDate: new Date().toISOString(),
      };

      await addDoc(collection(db, "notices"), notice);

      toast.success("Notice created successfully.");
      setData({ title: "", description: "", link: "" });
    } catch (error) {
      toast.error("Failed to create notice.");
      console.error(error);
    }
  };

  return (
    <section className="w-full h-600 flex items-center justify-center">
      <div className="lg:w-2/3 md:w-1/2  rounded-lg p-8 flex flex-col  md:mt-0 relative z-10 shadow-md bg-gray-300">
        <h2 className="text-gray-700  mb-1 font-semibold title-font text-xl -mt-2">
          Create a Notice
        </h2>
        <div className="relative mb-4">
          <label className="leading-7 text-base text-gray-600 ">
            Notice Title
          </label>
          <input
            type="title"
            id="title"
            name="title"
            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            onChange={handleChange}
            value={data.title}
          />
        </div>
        <div className="relative mb-4">
          <label className="leading-7 text-base text-gray-600">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            rows={6}
            className="w-full h-225 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200  text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            onChange={handleChange}
            value={data.description}
          ></textarea>
        </div>
        <div className="relative mb-4">
          <label className="leading-7 text-base text-gray-600">
            Notice Link
          </label>
          <input
            type="text"
            id="link"
            name="link"
            className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            onChange={handleChange}
            value={data.link}
          />
        </div>
        <motion.button
          {...btnClick}
          onClick={handleClick}
          className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
        >
          Post
        </motion.button>
      </div>
    </section>
  );
};

export default AddNewNotice;
