import React from "react";
import { Link } from "react-router-dom";

const VirtualTour = () => {
  return (
    <div className="pt-7">
      <div className="relative max-w-screen mx-auto md:pt-4 sm:pt-1 pb-8 p-4 ">
        <h1 className="text-slate-900 font-extrabold text-3xl sm:text-4xl lg:text-5xl tracking-tight text-center">
          Open Up Your <span className="text-orange-600">Future</span>
        </h1>
        <p className="mt-6 text-lg text-slate-600 text-center max-w-3xl md:mx-auto  sm:mx-2">
          To create leader of the future,equipped with the skills of tomorrow,
          enterpreneurs and responsible citizens.
        </p>
        <div className="mt-5 sm:mt-10 flex justify-center items-center space-x-6 text-sm ">
          <Link
            to="https://storage.net-fs.com/hosting/7380168/27/?fbclid=IwAR25o-3BXsi-in_oHgdxogd7EdIdccdqAlZVEhXCl5yaM_t9WDR-YUsrPsQ"
            target="_blank"
            className="hover:text-gray-200 bg-blue-700 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 text-white font-semibold h-12 px-6 sm:px-10 rounded-lg w-[70%] flex items-center justify-center sm:w-auto mx-8 text-lg sm:text-xl"
            id="about"
          >
            Take a virtual tour
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VirtualTour;
