import React, { useState, useEffect } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const [admissionStatus, setAdmissionStatus] = useState("");

  useEffect(() => {
    const fetchAdmissionStatus = async () => {
      try {
        const docRef = doc(db, "admissionStatus", "status");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setAdmissionStatus(docSnap.data().status);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchAdmissionStatus();
  }, []);

  const handleStatusChange = async (e) => {
    const newAdmissionStatus = e.target.value;

    if (newAdmissionStatus === admissionStatus) {
      return;
    }

    try {
      const docRef = doc(db, "admissionStatus", "status");
      await setDoc(docRef, { status: newAdmissionStatus });
      setAdmissionStatus(newAdmissionStatus);
      toast.success("Admission status updated successfully.");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update admission status.");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen overflow-hidden">
      <div className="rounded-lg p-8 bg-white shadow-md -mt-32">
        <h2 className="text-gray-700 font-semibold text-xl mb-4">
          Admission Status
        </h2>
        <div className="flex justify-center">
          <select
            value={admissionStatus}
            onChange={handleStatusChange}
            className="w-full border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 transition-colors duration-200 ease-in-out"
          >
            <option value="ADMISSION ENQUIRY">Admission Enquiry</option>
            <option value="ADMISSION OPEN">Admission Open</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Home;
