import React, { useState, useEffect } from "react";
import c1 from "../../assests/c1.jpg";
import c2 from "../../assests/c2.jpg";
import c3 from "../../assests/c3.jpg";
import c4 from "../../assests/c4.jpg";
import { motion } from "framer-motion";
import { fadeInOut } from "../../animations";

export default function SlidingInterval() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [c1, c3, c2, c4];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <div className="w-screen h-[50vh] sm:h-[90vh]">
      <motion.img {...fadeInOut}
        src={images[currentImage]}
        className="object-cover w-full h-full"
        alt="banner"
      />
    </div>
  );
}
