import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import GalleryBg from "../assests/c1.jpg";
import { storageRef } from "../firebase";
import { listAll, ref, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imageList = await listAll(ref(storageRef, "gallery/"));
        const imagePromises = imageList.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return { url, ref: itemRef };
        });
        const images = await Promise.all(imagePromises);
        setImageUrls(images);
      } catch (error) {
        toast.error("Something went wrong.");
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <section className="min-h-screen w-full">
      <div className="relative drop-shadow-md">
        <img
          className="w-full h-32 sm:h-44 object-cover blur-[1px] border-b-gray-500  opacity-80 brightness-75"
          src={GalleryBg}
          alt="Gallery Bg"
        />
        <p className="absolute left-[50%] right-[50%] w-max translate-x-[-50%]  top-16 text-gray-200 text-3xl sm:text-5xl md:text-6xl font-extrabold bg-black bg-opacity-50 px-4 py-2 rounded-xl">
          Life at Orbit
        </p>
      </div>

      <div className="grid cursor-pointer sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 lg:max-w-[1250px] xl:max-w-full xl:px-4 2xl:grid-cols-5 2xl:w-full  mx-auto py-5 gap-x-2 gap-y-1 px-2 lg:px-0 ">
        {imageUrls?.map(({ url, ref }, index) => (
          <Link to={url} target="_blank">
            <div key={index} className="overflow-hidden h-[14rem] md:h-[12rem] w-full ">
              <LazyLoadImage
                effect="opacity"
                className="object-cover aspect-video hover:scale-105 duration-300 z-0 group-hover:opacity-80"
                src={url}
                alt="gallery"
              />
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default Gallery;
