import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import store from './redux/store'
import { AnimatePresence } from 'framer-motion';
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AnimatePresence>
      <Provider store={store}>

        <App />
      </Provider>


    </AnimatePresence>
  </BrowserRouter>
);


reportWebVitals();
