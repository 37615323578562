import React from "react";
import { MdLogout, MdSearch } from "react-icons/md";
import { BsFillBellFill, BsToggles2 } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { btnClick } from "../../animations";

// import { logout } from '../../store/user/authSlice'
// import { clearUserData } from '../../store/user/currUserSlice'
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/authSlice";

const DBHeader = () => {
  // const { userData } = useSelector(state => state.currUser)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <section className="w-full flex items-center justify-between gap-3  px-3 ">
      {/* left */}
      <p className="text-2xl text-headingColor font-semibold  font-sans ">
        Welcome to Orbit College.
        <span className="block text-base text-black">Hello Admin !</span>
      </p>

      {/* right */}

      <div className="flex items-center justify-center gap-4">
        <div className="flex items-center justify-center gap-4">
          <div className="flex items-center justify-center gap-3 px-4 py-2 backdrop-blur-md rounded-md drop-shadow-lg  bg-gray-100 border-2 border-gray-300">
            <MdSearch className="text-gray-700 text-2xl " />
            <input
              type="text"
              placeholder="Search here.."
              className=" bg-transparent w-48 outline-none text-base"
            />

            <BsToggles2 className="text-gray-700 text-2xl" />
          </div>

          <motion.div
            {...btnClick}
            className="w-10 bg-gray-100 rounded-md cursor-pointer backdrop-blur-md shadow-md flex items-center justify-center h-10 gap-2 "
          >
            <BsFillBellFill className="text-gray-500 text-xl h-full" />
          </motion.div>

          <div className="flex items-center  justify-center gap-2">
            <div className="w-10 h-10 rounded-md shadow-md cursor-pointer overflow-hidden">
              <motion.img
                className="w-full h-full object-cover rounded-md"
                src="https://www.w3schools.com/howto/img_avatar.png"
                whileHover={{ scale: 1.1 }}
                referrerPolicy="no-referrers"
              />
            </div>

            <motion.div
              {...btnClick}
              className="w-10 rounded-md cursor-pointer backdrop-blur-md shadow-md flex items-center justify-center h-10  bg-gray-100"
            >
              <MdLogout
                className="text-2xl text-textColor group-hover::text-headingColor h-10"
                onClick={() => {
                  dispatch(logout());
                  // dispatch(clearUserData());
                  navigate("/");
                }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DBHeader;
