import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";

const Notice = () => {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "notices"));
        const noticesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const sortedNotices = noticesData.sort((a, b) => {
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
        setNotices(sortedNotices);
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotices();
  }, []);

  const handleDelete = async (noticeId) => {
    try {
      await deleteDoc(doc(db, "notices", noticeId));
      setNotices((prevNotices) =>
        prevNotices.filter((notice) => notice.id !== noticeId)
      );
      toast.success("Notice deleted successfully.");
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete notice.");
    }
  };

  return (
    <section className="w-full min-h-[600px] flex items-center justify-center overflow-hidden mt-10">
      <div className="lg:w-[87%] md:w-full rounded-lg p-8 flex flex-col md:mt-0 relative z-10 shadow-md bg-gray-300 ">
        <h2 className="text-gray-700 mb-1 font-semibold title-font text-xl -mt-2">
          Notices
        </h2>
        {notices?.length === 0 ? (
          <p className="text-gray-700">No notices available.</p>
        ) : (
          <ul className="space-y-4">
            {notices?.map((notice) => (
              <li
                key={notice.id}
                className="bg-white rounded-lg p-4 flex items-center justify-between"
              >
                <div>
                  <h3 className="text-gray-900 font-semibold">
                    {notice.title}
                  </h3>
                  <p className="text-gray-700 mt-1">{notice.description}</p>
                  {notice.link && (
                    <Link to={notice.link} className="text-indigo-500 mt-1">
                      {notice.link}
                    </Link>
                  )}
                </div>
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => handleDelete(notice.id)}
                >
                  <MdDelete size={24} />
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default Notice;
