import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./authAction";

const userToken = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : null;

// const userId = localStorage.getItem("userId")
//     ? localStorage.getItem("userId")
//     : null;

const initialState = {

    loading: false,
    error: null,
    success: false,
    userToken,
    login: false
};


const authSlice = createSlice({
    name: "user-auth",
    initialState: initialState,

    reducers: {

        logout: (state, { payload }) => {
            localStorage.removeItem("userToken");
            state.loading = false;
            state.userToken = null;
            state.error = null;
            state.login = false

        },

        clearFields: (state, { payload }) => {
            state.success = false;
            state.loading = false;
            state.error = null;
        },

        toggleLogin: (state) => {
            state.login = !state.login
        }


    },




    extraReducers: {

        [userLogin.fulfilled]: (state, { payload }) => {
            state.success = true;
            // console.log(payload);
            state.error = null;
            state.userToken = payload
            localStorage.setItem("userToken", payload);





        },
        [userLogin.pending]: (state) => {

            state.loading = true;
            state.error = null;
            state.success = false


        },
        [userLogin.rejected]: (state, { payload }) => {
            console.log(payload);
            state.error = payload
            state.loading = false
            state.success = false;


        },



    }

})

export default authSlice.reducer;
export const { setError, logout, clearFields, toggleLogin } = authSlice.actions;