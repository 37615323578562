import React from "react";
import Principal from "../../assests/principal.png";
import Chairman from "../../assests/chairman.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const TeacherMessage = () => {
  return (
    <div className="w-full  bg-gray-200 pt-10 pb-10 lg:px-0 md:px-3 px-3">
      <div className="max-w-screen-lg  bg-card sm:card flex   flex-col-reverse pl-5  md:pl-0 md:flex-row pt-4 pb-10 md:py-3 mx-auto items-center pr-10 gap-5 lg:gap-10 rounded-lg  shadow-md ">
        <aside className=" hidden md:block bg-orange-500 rounded-tr-md rounded-br-md px-4 py-4 text-xl text-gray-100 font-semibold ">
          Chairman's Message
        </aside>

        <div className="text-black text-opacity-80 text-[15px] sm:text-base  relative lg:text-[17px] text-justify">
          Orbit College is a prestigious institution offering diverse programs in Science,
          Management, Hotel Management, Law, Fashion Design, and Interior Design. With a focus on
          innovation and creativity, they empower students to recognize their strengths and envision
          a successful future. Join Orbit College to unlock your boundless potential.
          <p className="absolute text-[14px] sm:text-base -bottom-7 -right-4  sm:-bottom-6 lg:-bottom-10 sm:right-0 font-semibold">
            -- Dipendra Raj Dhakal
          </p>
        </div>

        <p className="text-lg font-semibold md:hidden">Chairman's Message</p>
        <LazyLoadImage
          src={Chairman}
          className="shadow-md w-44 h-56 md:h-auto md:w-36 rounded-md"
          alt="chairman"
        />
      </div>

      <div className="max-w-screen-lg hidden  bg-card md:flex flex-row-reverse py-3 mx-auto items-center pl-10 gap-6 lg:gap-10 rounded-lg mt-10  shadow-md">
        <aside className="bg-orange-500 rounded-tl-md rounded-bl-md px-4 py-4 text-xl text-gray-100 font-semibold">
          Principal's Message
        </aside>
        <div className=" text-black text-opacity-80  lg:text-[17px] relative text-justify  leading-normal">
          Dear Students,Parents, and Well-wishers, Welcome to our college. As a Principal,I am
          thrilled to extend a warm greeting through our prospectus. Our institution is dedicated to
          fostering academic excellence, personal growth and preparing student for future
          leadership. Join us as we embark on this exciting jorney of learning growth and success.
          <p className="absolute -bottom-6 lg:-bottom-10 right-0 font-semibold">
            -- Ram Prasad Adhikari
          </p>
        </div>

        <LazyLoadImage
          src={Principal}
          className="shadow-md w-36 rounded-md"
          alt="chairman
        "
        />
      </div>

      <div className="max-w-screen-lg   bg-card sm:card flex  md:hidden flex-col-reverse pl-10 md:pl-0 md:flex-row pt-4 pb-10 md:py-3 mx-auto items-center pr-10 gap-5 lg:gap-10 rounded-lg  mt-10">
        <aside className=" hidden md:block bg-orange-500 rounded-tr-md rounded-br-md px-4 py-4 text-xl text-gray-100 font-semibold ">
          Chairman's Message
        </aside>

        <div className="text-black text-opacity-80 text-[15px] sm:text-base leading-normal relative lg:text-[17px] text-justify">
          Dear Students,Parents, and Well-wishers, Welcome to our esteemed institution. As a
          Principal,I am thrilled to invite you to embark on a transformative education journey with
          us. Our college is commited to academic excellence, innovation and holistic development.
          Join us as we shapre a brighter future together.
          <p className="absolute text-[14px] sm:text-base -bottom-7 -right-4  sm:-bottom-6 lg:-bottom-10 sm:right-0 font-semibold">
            -- Ram Prasad Adhikari
          </p>
        </div>

        <p className="text-lg font-semibold md:hidden">Principal's Message</p>
        <LazyLoadImage
          src={Principal}
          className="shadow-md w-44 h-56 md:h-auto md:w-36 rounded-md"
          alt="principal"
        />
      </div>
    </div>
  );
};

export default TeacherMessage;
