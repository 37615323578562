import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import { HiSpeakerphone } from "react-icons/hi";
import { motion } from "framer-motion";
import { btnClick } from "../../animations";

const Notice = () => {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "notices"));
        const noticesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const sortedNotices = noticesData.sort((a, b) => {
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
        setNotices(sortedNotices);
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotices();
  }, []);

  return (
    <div className="max-w-[1200px] mx-auto min-h-225   pt-16 pb-12 px-1 sm:px-4 md:px-4 ">
      <section className="w-full">
        <p className="text-blue-800 text-3xl font-semibold px-4 text-center">
          NEWS AND NOTICES
        </p>
        <ul className="space-y-0 flex flex-col md:flex-row w-full gap-4 md:gap-10 mt-5">
          {notices?.slice(0, 2).map((notice, index) => (
            <div className=" w-full md:w-1/2 flex shadow-lg bg-gray-100" key={index}>
              <p className="flex md:ml-4 ml-2">
                <HiSpeakerphone className="text-4xl mt-5 text-blue-800" />
              </p>
              <li
                key={notice.id}
                className="rounded-lg p-4 flex  justify-between "
              >
                <div>
                  <h3 className="text-gray-900 font-semibold sm:text-lg">
                    {notice.title}
                  </h3>
                  <p className="text-gray-700 line-clamp-4 text-justify mt-1">
                    {notice.description}
                  </p>
                  {notice.link && (
                    <Link
                      to={notice.link}
                      className="text-indigo-500 mt-2 inline-block hover:underline"
                    >
                      Read More
                    </Link>
                  )}
                </div>
              </li>
            </div>
          ))}
        </ul>
        <motion.button className="w-full mt-2" {...btnClick}>
          <Link
            to="/notices"
            className=" mt-2 flex items-center w-44 justify-center h-10 px-6 font-semibold tracking-wide text-white transition duration-200 rounded shadow-md hover:text-gray-200 bg-orange-700 hover:bg-orange-500 focus:shadow-outline focus:outline-none mx-auto"
          >
            SEE MORE
          </Link>
        </motion.button>
      </section>
    </div>
  );
};

export default Notice;
