import { getDownloadURL, listAll, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { storageRef } from "../../firebase";
import { toast } from "react-toastify";
const Features = () => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imageList = await listAll(ref(storageRef, "featured/"));
        const imagePromises = imageList.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return { url, ref: itemRef };
        });
        const images = await Promise.all(imagePromises);
        setImageUrls(images);
      } catch (error) {
        toast.error("Something went wrong.");
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <>
      <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 pb-12">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-white uppercase rounded-full bg-orange-400">
              APART FROM OTHERS
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block text-blue-800">
              <svg
                viewBox="0 0 52 24"
                fill="gray"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="3071a3ad-db4a-4cbe-ac9a-47850b69e4b7"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7"></circle>
                  </pattern>
                </defs>
                <rect
                  fill="url(#3071a3ad-db4a-4cbe-ac9a-47850b69e4b7)"
                  width="52"
                  height="24"
                ></rect>
              </svg>
              WHY ORBIT COLLEGE ?
            </span>
          </h2>
          <p className=" text-gray-700">
            Why you should choose Oribt College for Your Academic Success:
          </p>
        </div>
        <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
          <div className="flex flex-col justify-center text-md">
            <div className="flex">
              <div className="mr-4">
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-50">
                  <svg
                    stroke="blue"
                    viewBox="0 0 52 52"
                    className="w-8 h-8 text-deep-purple-accent-400"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    ></polygon>
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-2 font-semibold leading-5 text-lg text-gray-800">
                  Exceptional Faculty
                </h6>
                <p className=" text-gray-900">
                  Orbit College has a highly experienced and diverse faculty
                  dedicated to delivering top-quality education.
                </p>
                <hr className="w-full my-6 border-gray-300" />
              </div>
            </div>
            <div className="flex">
              <div className="mr-4">
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-50">
                  <svg
                    stroke="blue"
                    viewBox="0 0 52 52"
                    className="w-8 h-8 text-deep-purple-accent-400"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    ></polygon>
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-2 font-semibold leading-5 text-lg text-gray-800">
                  Comprehensive Learning
                </h6>
                <p className=" text-gray-900">
                  The college adopts a self-exploratory, systematic, and
                  scientific approach to ensure students receive a well-rounded
                  education, preparing them for global citizenship.
                </p>
                <hr className="w-full my-6 border-gray-300" />
              </div>
            </div>
            <div className="flex">
              <div className="mr-4">
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-50">
                  <svg
                    stroke="blue"
                    viewBox="0 0 52 52"
                    className="w-8 h-8 text-deep-purple-accent-400"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    ></polygon>
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-2 font-semibold leading-5 text-lg text-gray-800">
                  Holistic Development
                </h6>
                <p className=" text-gray-900">
                  Students have abundant opportunities to show their talents
                  through co-curricular and extra-curricular activities,
                  fostering their overall growth and leadership skills
                </p>
                <hr className="w-full my-6 border-gray-300" />
              </div>
            </div>
            <div className="flex">
              <div className="mr-4">
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-50">
                  <svg
                    stroke="blue"
                    viewBox="0 0 52 52"
                    className="w-8 h-8 text-deep-purple-accent-400"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    ></polygon>
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-2 font-semibold leading-5 text-lg text-gray-800">
                  Attractive College
                </h6>
                <p className=" text-gray-900">
                  Orbit College offers a beautiful and serene campus with lush
                  greenery, providing an ideal environment for learning.
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <img
              src={imageUrls[0]?.url}
              alt=""
              className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
              loading="lazy"
            />
            <img
              src={imageUrls[1]?.url}
              alt=""
              className="object-cover w-full h-48 rounded shadow-lg"
              loading="lazy"
            />
            <img
              src={imageUrls[2]?.url}
              alt=""
              className="object-cover w-full h-48 rounded shadow-lg"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
